import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Link,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  SlideFade,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";

import PagedFlowLayout from "./PagedFlowLayout";
import PagedFlowLayoutHeader from "./PagedFlowLayoutHeader";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowBackIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import PagedFlowLayoutFormSection from "./PagedFlowLayoutFormSection";
import PagedFlowLayoutNavSection from "./PagedFlowLayoutNavSection";

import { centres, CenterInfo } from "../constants/centres";
import _ from "lodash";
import { RegistrationFormProps } from "../../RegistrationForm";
import { useState } from "react";

// @ts-ignore
import MspTermsAndConditions from "../assets/msp-t&c-2024.pdf";

type CompletionInfoProps = {
  registrationCentre: string;
  worksheetArrangement: string;
  worksheetArrangementCentre: string;
  howDidYouHearAboutUs: string[];
  howDidYouHearAboutUsOthers?: string;
  hasAcknowledged: boolean;
};

const schema = z.object({
  registrationCentre: z.string(),
  worksheetArrangement: z.string().optional(),
  worksheetArrangementCentre: z.string().optional(),
  howDidYouHearAboutUs: z
    .string()
    .array()
    .min(1, { message: "Please select at least one option." }),
  howDidYouHearAboutUsOthers: z
    .string()
    .max(50, { message: "Please enter a maximum of 50 characters." })
    .optional(),
  hasAcknowledged: z.boolean({
    message: "Please acknowledge our Terms and Conditions.",
  }),
});

function CompletionInfo({
  currPage,
  pageInFlow,
  nextPage,
  prevPage,
  registrationData,
  updateRegistration,
  submitRegistration,
}: {
  currPage: number;
  pageInFlow: number;
  nextPage: () => void;
  prevPage: () => void;
  registrationData: RegistrationFormProps;
  updateRegistration: (pageData: any) => void;
  submitRegistration: (data: RegistrationFormProps) => Promise<void>;
}) {
  const {
    register,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
    resetField,
  } = useForm<CompletionInfoProps>({
    resolver: zodResolver(schema),
    defaultValues: {
      howDidYouHearAboutUs: [],
      ...registrationData,
    },
  });

  const toast = useToast({ position: "top", isClosable: true });
  const [acknowledgementChecks, setAcknowledgementChecks] = useState({
    terms: false,
    accuracy: false,
    refusal: false,
  });

  const onAcknowledgementChange =
    (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      // First, update acknowledgementChecks
      const updatedChecks = {
        ...acknowledgementChecks,
        [field]: e.target.checked,
      };
      setAcknowledgementChecks(updatedChecks);

      // Then, set hasAcknowledged based on the updated state
      const allChecked =
        updatedChecks.terms && updatedChecks.accuracy && updatedChecks.refusal;
      if (allChecked) {
        setValue("hasAcknowledged", true);
      } else {
        setValue("hasAcknowledged", false);
      }
    };

  const onSubmit = async (data: CompletionInfoProps) => {
    // Handle form submission here
    console.log("date received", data);

    try {
      // TODO: Handle updating parent object
      updateRegistration(data);
      // Have to call it with the latest data because updateRegistration might not complete in time
      await submitRegistration({ ...registrationData, ...data });
    } catch (e) {
      toast({
        title: "Unable to submit your registration.",
        description:
          "Please check that all information is correct and try again. If the problem persists, please contact helpdesk@mindstretcher.com.",
        status: "error",
        duration: 5000,
      });
      return;
    }

    window.location.href = "/success";
  };

  // Using watch as a hack to trigger re-render
  const watchRegistrationCentre = watch("registrationCentre");
  const watchWorksheetArrangement = watch("worksheetArrangement");
  const watchWorksheetArrangementCentre = watch("worksheetArrangementCentre");
  const watchHowDidYouHearAboutUs = watch("howDidYouHearAboutUs");

  // Get the details for the selected worksheet arrangement centre
  const worksheetArrangementCentreInfo = centres.find(
    (centre) => centre.internalName === watchWorksheetArrangementCentre
  );

  return (
    <PagedFlowLayout>
      <PagedFlowLayoutHeader title="Completion" subtitle="Step 4 of 4" />
      <SlideFade in={currPage === pageInFlow} offsetX="100px" offsetY="0px">
        <PagedFlowLayoutFormSection>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack
              gap={5}
              alignItems={"flex-start"}
              maxWidth={{ base: "100%", lg: "80%" }}
            >
              <FormControl isInvalid={!!errors.registrationCentre} isRequired>
                <FormLabel htmlFor="registrationCentre">
                  Registration Centre
                </FormLabel>
                <Controller
                  control={control}
                  name="registrationCentre"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      placeholder="Please select a centre"
                      id="registrationCentre"
                      value={value}
                      onChange={(val) => {
                        // Also need to reset the worksheet arrangement and centre choices when the centre changes
                        resetField("worksheetArrangement");
                        resetField("worksheetArrangementCentre");
                        onChange(val);
                      }}
                    >
                      {centres.map((centre: CenterInfo) => (
                        <option
                          key={centre.externalName}
                          value={centre.internalName}
                        >
                          {centre.externalName}
                        </option>
                      ))}
                    </Select>
                  )}
                />
                {errors.registrationCentre && (
                  <FormErrorMessage>
                    {errors.registrationCentre.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              {getValues().registrationCentre === "Mind Stretcher Education" ? (
                <FormControl
                  isInvalid={!!errors.worksheetArrangement}
                  isRequired
                >
                  <FormLabel htmlFor="worksheetArrangement">
                    Worksheets Arrangement for Online Class
                  </FormLabel>
                  <RadioGroup
                    onChange={() => {
                      // Reset centre when the worksheet arrangement option changes
                      resetField("worksheetArrangementCentre");
                    }}
                  >
                    <Stack direction="column">
                      <Radio value="Mail" {...register("worksheetArrangement")}>
                        <Text display="inline" fontWeight={500}>
                          Mail
                        </Text>{" "}
                        - <i>by post which takes 5 working days</i>
                      </Radio>
                      <VStack alignItems="flex-start">
                        <Radio
                          value="Collection at the Centre"
                          {...register("worksheetArrangement")}
                        >
                          <Text width="max-content" fontWeight={500}>
                            Collection at the centre
                          </Text>
                        </Radio>
                        {getValues().worksheetArrangement ===
                        "Collection at the Centre" ? (
                          <FormControl
                            width="max-content"
                            isInvalid={!!errors.worksheetArrangementCentre}
                            isRequired
                          >
                            <Select
                              id="worksheetArrangementCentre"
                              placeholder="Please select a centre"
                              {...register("worksheetArrangementCentre")}
                              width="fit-content"
                            >
                              {centres
                                // Exclude online classes from this dropdown
                                .filter(
                                  (centre: CenterInfo) =>
                                    centre.internalName !==
                                    "Mind Stretcher Education"
                                )
                                // Then map to display all remaining options
                                .map((centre: CenterInfo) => (
                                  <option
                                    key={centre.internalName}
                                    value={centre.internalName}
                                  >
                                    {centre.externalName}
                                  </option>
                                ))}
                            </Select>
                          </FormControl>
                        ) : null}
                        {/* Show address of centre selected for worksheet collection */}
                        {worksheetArrangementCentreInfo ? (
                          <Box
                            backgroundColor="white"
                            border="1px solid"
                            borderColor="gray.100"
                            borderRadius="md"
                            padding={4}
                          >
                            <VStack alignItems="flex-start" gap={0}>
                              <Text fontWeight={500}>
                                {worksheetArrangementCentreInfo.externalName}
                              </Text>
                              <Text fontWeight={300}>
                                {worksheetArrangementCentreInfo.address}
                              </Text>
                              <Text fontWeight={300}>
                                {worksheetArrangementCentreInfo.landline}{" "}
                                {worksheetArrangementCentreInfo.landline &&
                                worksheetArrangementCentreInfo.mobile
                                  ? "/ "
                                  : ""}
                                {worksheetArrangementCentreInfo.mobile}
                              </Text>
                              <Text fontWeight={300}>
                                {worksheetArrangementCentreInfo.email}
                              </Text>
                            </VStack>
                          </Box>
                        ) : null}
                      </VStack>
                      <Radio
                        value="Self-Print"
                        {...register("worksheetArrangement")}
                      >
                        <Text display="inline" fontWeight={500}>
                          Self-print
                        </Text>{" "}
                        -{" "}
                        <i>
                          you will need to install an app to access the
                          worksheets in PDC format
                        </i>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  {errors.worksheetArrangement && (
                    <FormErrorMessage>
                      {errors.worksheetArrangement.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              ) : null}
              <FormControl
                as="fieldset"
                isInvalid={!!errors.howDidYouHearAboutUs}
              >
                <FormLabel as="legend" htmlFor="howDidYouHearAboutUs">
                  How did you hear about us?{" "}
                  <Text
                    display="inline"
                    fontSize="14px"
                    fontWeight={400}
                    fontStyle="italic"
                  >
                    (please select at least one option)
                  </Text>
                </FormLabel>
                <Controller
                  control={control}
                  name="howDidYouHearAboutUs"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <SimpleGrid columns={3} spacing={4}>
                      <Checkbox
                        id="howDidYouHearAboutUs"
                        isChecked={_.includes(value, "Brochure")}
                        onChange={(event) => {
                          if (event.target.checked) {
                            onChange([...value, "Brochure"]);
                          } else {
                            onChange(_.without(value, "Brochure"));
                          }
                        }}
                      >
                        Brochure
                      </Checkbox>
                      <Checkbox
                        id="howDidYouHearAboutUs"
                        isChecked={_.includes(value, "EDM (Email)")}
                        onChange={(event) => {
                          if (event.target.checked) {
                            onChange([...value, "EDM (Email)"]);
                          } else {
                            onChange(_.without(value, "EDM (Email)"));
                          }
                        }}
                      >
                        EDM (Email)
                      </Checkbox>
                      <Checkbox
                        id="howDidYouHearAboutUs"
                        isChecked={_.includes(value, "Search Engine")}
                        onChange={(event) => {
                          if (event.target.checked) {
                            onChange([...value, "Search Engine"]);
                          } else {
                            onChange(_.without(value, "Search Engine"));
                          }
                        }}
                      >
                        Search Engine
                      </Checkbox>
                      <Checkbox
                        id="howDidYouHearAboutUs"
                        isChecked={_.includes(value, "Events")}
                        onChange={(event) => {
                          if (event.target.checked) {
                            onChange([...value, "Events"]);
                          } else {
                            onChange(_.without(value, "Events"));
                          }
                        }}
                      >
                        Events
                      </Checkbox>
                      <Checkbox
                        id="howDidYouHearAboutUs"
                        isChecked={_.includes(value, "Website")}
                        onChange={(event) => {
                          if (event.target.checked) {
                            onChange([...value, "Website"]);
                          } else {
                            onChange(_.without(value, "Website"));
                          }
                        }}
                      >
                        Website
                      </Checkbox>
                      <Checkbox
                        id="howDidYouHearAboutUs"
                        isChecked={_.includes(value, "Siblings")}
                        onChange={(event) => {
                          if (event.target.checked) {
                            onChange([...value, "Siblings"]);
                          } else {
                            onChange(_.without(value, "Siblings"));
                          }
                        }}
                      >
                        Siblings
                      </Checkbox>
                      <Checkbox
                        id="howDidYouHearAboutUs"
                        isChecked={_.includes(value, "Parent Forum")}
                        onChange={(event) => {
                          if (event.target.checked) {
                            onChange([...value, "Parent Forum"]);
                          } else {
                            onChange(_.without(value, "Parent Forum"));
                          }
                        }}
                      >
                        Parent Forum
                      </Checkbox>
                      <Checkbox
                        id="howDidYouHearAboutUs"
                        isChecked={_.includes(value, "Word of Mouth")}
                        onChange={(event) => {
                          if (event.target.checked) {
                            onChange([...value, "Word of Mouth"]);
                          } else {
                            onChange(_.without(value, "Word of Mouth"));
                          }
                        }}
                      >
                        Word of Mouth
                      </Checkbox>
                      <Checkbox
                        id="howDidYouHearAboutUs"
                        isChecked={_.includes(value, "Instagram")}
                        onChange={(event) => {
                          if (event.target.checked) {
                            onChange([...value, "Instagram"]);
                          } else {
                            onChange(_.without(value, "Instagram"));
                          }
                        }}
                      >
                        Instagram
                      </Checkbox>
                      <Checkbox
                        id="howDidYouHearAboutUs"
                        isChecked={_.includes(value, "Facebook")}
                        onChange={(event) => {
                          if (event.target.checked) {
                            onChange([...value, "Facebook"]);
                          } else {
                            onChange(_.without(value, "Facebook"));
                          }
                        }}
                      >
                        Facebook
                      </Checkbox>
                      <Checkbox
                        id="howDidYouHearAboutUs"
                        isChecked={_.includes(value, "WeChat")}
                        onChange={(event) => {
                          if (event.target.checked) {
                            onChange([...value, "WeChat"]);
                          } else {
                            onChange(_.without(value, "WeChat"));
                          }
                        }}
                      >
                        WeChat
                      </Checkbox>
                      <Checkbox
                        id="howDidYouHearAboutUs"
                        isChecked={_.includes(value, "Others")}
                        onChange={(event) => {
                          if (event.target.checked) {
                            onChange([...value, "Others"]);
                          } else {
                            // if unchecked, reset others field too
                            resetField("howDidYouHearAboutUsOthers");
                            onChange(_.without(value, "Others"));
                          }
                        }}
                      >
                        Others
                      </Checkbox>
                    </SimpleGrid>
                  )}
                />
                {/* <Select
                  id="howDidYouHearAboutUs"
                  placeholder="How did you hear about us?"
                  {...register("howDidYouHearAboutUs")}
                >
                  <option value="Newspaper">Newspaper</option>
                  <option value="Whatsapp">Whatsapp</option>
                  <option value="Friends">Friends</option>
                  <option value="Social Media">Social Media</option>
                  <option value="Other">Other</option>
                </Select> */}
                {errors.howDidYouHearAboutUs && (
                  <FormErrorMessage>
                    {errors.howDidYouHearAboutUs.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              {_.includes(getValues().howDidYouHearAboutUs, "Others") && (
                <FormControl
                  isInvalid={!!errors.howDidYouHearAboutUsOthers}
                  isRequired
                >
                  <FormLabel htmlFor="howDidYouHearAboutUsOthers">
                    How did you hear about us (others)?
                  </FormLabel>
                  <Input
                    id="howDidYouHearAboutUsOthers"
                    {...register("howDidYouHearAboutUsOthers")}
                  />
                  {errors.howDidYouHearAboutUsOthers && (
                    <FormErrorMessage>
                      {errors.howDidYouHearAboutUsOthers.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              )}
              <Text fontSize="20px" fontWeight={700} mt={5}>
                Declarations
              </Text>
              <FormControl isInvalid={!!errors.hasAcknowledged} isRequired>
                <FormLabel htmlFor="hasAcknowledged">
                  Please carefully read through the following statements:
                </FormLabel>
                <VStack alignItems="flex-start" gap={4}>
                  <Checkbox
                    id="terms"
                    variant="checkbox-4px"
                    isChecked={acknowledgementChecks.terms}
                    onChange={onAcknowledgementChange("terms")}
                  >
                    {_.includes(
                      window.location.hostname,
                      "masterspeakers.com"
                    ) ? (
                      <>
                        I have read and agree to{" "}
                        <Link
                          href={MspTermsAndConditions}
                          color="#013768"
                          fontWeight={600}
                          // target="_blank"
                          isExternal
                        >
                          Master Speaker's Terms and Conditions
                        </Link>
                        .
                      </>
                    ) : (
                      <>
                        I have read and agree to{" "}
                        <Link
                          href="https://www.mindstretcher.com/terms-and-conditions"
                          color="#013768"
                          fontWeight={600}
                          isExternal
                        >
                          Mind Stretcher's Terms and Conditions
                        </Link>
                        .
                      </>
                    )}
                  </Checkbox>
                  <Checkbox
                    id="accuracy"
                    variant="checkbox-4px"
                    isChecked={acknowledgementChecks.accuracy}
                    onChange={onAcknowledgementChange("accuracy")}
                  >
                    I certify that the information provided in this form is
                    complete, accurate, and true to the best of my knowledge
                  </Checkbox>
                  <Checkbox
                    id="refusal"
                    variant="checkbox-4px"
                    isChecked={acknowledgementChecks.refusal}
                    onChange={onAcknowledgementChange("refusal")}
                  >
                    I understand that my child/ward may be refused registration
                    or have his/her enrolment in the Registered Course(s)
                    terminated in the event that I fail to provide complete,
                    accurate, and correct information as requested here
                  </Checkbox>
                </VStack>
                {errors.hasAcknowledged && (
                  <FormErrorMessage>
                    {errors.hasAcknowledged.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </VStack>
            <PagedFlowLayoutNavSection>
              <Button
                variant="ms-outline"
                onClick={prevPage}
                leftIcon={<ArrowBackIcon pt="1px" />}
              >
                Back
              </Button>
              <Button
                type="submit"
                variant="ms-solid"
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                Submit Registration
              </Button>
            </PagedFlowLayoutNavSection>
          </form>
        </PagedFlowLayoutFormSection>
      </SlideFade>
    </PagedFlowLayout>
  );
}

export default CompletionInfo;
